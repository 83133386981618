<template>
  <div class="header-container">
    <base-header :name="'Podcast'" :textColor="'#202020'"></base-header>
  </div>

  <div class="podcast-container" :class="{ mobile: isMobile }">
    <base-card class="card" :header="'Mój Podcast'" :image="podcastPhoto">
      <p class="text">
        Od stresu do gastrobiznesu to podcast dla przedsiębiorców i managerów z
        branży gastronomicznej.
      </p>
      <p class="text">
        Znajdziesz tu wskazówki dotyczące prowadzenia biznesu gastronomicznego.
        W podcaście poruszam takie tematy, jak: zarządzanie zespołem, rozwój
        liderów, komunikacja, obsługa gościa, sprzedaż, finanse, procesy i
        procedury oraz inne bieżące wyzwania jakie niesie ze sobą gastronomia.
      </p>
      <p class="text">
        Znajdziesz tu również mnóstwo inspiracji oraz wskazówki jak poukładać
        swój gastrobiznes. Ja nazywam się Martyna Więckowiak i pomagam
        przedsiębiorcom z branży gastro stworzyć najlepszą wersję swojej firmy,
        z której będą mogli być dumni.
      </p></base-card
    >
    <base-card class="card" :header="'Najnowsze odcinki'">
      <div ref="podcast">
        <div class="podcasts-list" :class="{ mobile: isMobile }">
          <iframe
            title="spotify 1 podcast"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/show/2zfA6OiWBZGZtF4jJKUehV?utm_source=generator&theme=0"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
          <iframe
            title="spotify 2 podcast"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/episode/5AaTS9LShpoLWj3oGE8uAb?utm_source=generator&theme=0"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
          <iframe
            title="spotify 2 podcast"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/episode/216MB3lnsbjDy0bTLUYpDd?utm_source=generator&theme=0"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
          <iframe
            title="spotify 2 podcast"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/episode/1IYNuZJ3liDYP8cZ70mEeH?utm_source=generator&theme=0"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>

        <p class="bold">Zobacz wszystkie odcinki na:</p>

        <div class="icons" :class="{ mobile: isMobile }">
          <div class="icon-container" :class="{ mobile: isMobile }">
            <a
              href="https://open.spotify.com/show/2zfA6OiWBZGZtF4jJKUehV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="logo spotify"
                class="icon"
                :class="{ mobile: isMobile }"
                src="@/assets/elements/spotify-logo.svg"
              />
              <div class="icon-text" :class="{ mobile: isMobile }">Spotify</div>
            </a>
          </div>

          <div class="icon-container" :class="{ mobile: isMobile }">
            <a
              href="https://podcasts.apple.com/us/podcast/od-stresu-do-gastrobiznesu/id1742209704"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="icon"
                alt="logo apple podcasts"
                :class="{ mobile: isMobile }"
                src="@/assets/elements/Podcasts_(iOS).svg"
              />
              <div class="icon-text" :class="{ mobile: isMobile }">
                Apple Podcast
              </div>
            </a>
          </div>
        </div>
      </div></base-card
    >
  </div>
</template>

<script>
export default {
  metaInfo: {
    title:
      'Nawigacja Mobilna - Odkryj Nasze Szkolenia, Podcasty, Blogi i Więcej!',
    meta: [
      {
        name: 'description',
        content:
          'Użyj naszej intuicyjnej nawigacji mobilnej, aby łatwo eksplorować różne sekcje naszej strony. Znajdź wszystko, od szkoleń, przez podcasty, po blogi i kontakt. Zobacz, co mamy do zaoferowania, gdziekolwiek jesteś i kiedykolwiek chcesz.'
      },
      {
        name: 'keywords',
        content:
          'mobilna nawigacja, szkolenia online, podcasty, blogi, kontakt, intuicyjna nawigacja, dostępność'
      }
    ]
  },
  data() {
    return {
      isHeaderVisible: false,
      podcastPhoto: require('@/assets/pictures/other/07c.jpg')
    }
  },
  mounted() {
    this.setupObserver()
  },
  methods: {
    setupObserver() {
      const alwaysVisibleElement = this.$refs.podcast // referencja do elementu, który jest zawsze widoczny
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !this.isMobile) {
              this.activateAnimations()
            }
          })
        },
        { threshold: 0.1 }
      )

      observer.observe(alwaysVisibleElement)
    },

    activateAnimations() {
      const elementsToAnimate = [this.$refs.info]

      elementsToAnimate.forEach(el => {
        if (el) {
          el.classList.add('animated')
        }
      })
    },

    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000)
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.header-container {
  margin-top: 2rem;
  width: 100%;
}

.header-container.mobile {
  margin-bottom: 2rem;
}

.podcasts-list {
  margin-top: 1rem;
  max-width: 24rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.podcasts-list.mobile {
  width: 90%;
}

.podcast-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
}

.podcast-container.mobile {
}

.podcast-container.mobile {
  grid-template-columns: 1fr;
}

.foto {
  width: 25rem;
  height: 25rem;
  object-fit: cover;
  object-position: 30% 80%;
  border-radius: 5px;
}

.foto.mobile {
  width: 100%;
}

.info.animated {
  animation: fadeIn 1s forwards 0.5s;
}

.icon {
  width: 2rem;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.icon.mobile {
  width: 3rem;
}

.icons {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-left: 0rem;
  padding: 0px;
  grid-area: icons;
  margin-left: auto;
  margin-right: auto;
  width: 10rem;
}
.icons.mobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
}

.icon-text {
  color: $gray;
}

.info {
  opacity: 0;
  position: relative;
}

.info.mobile {
  opacity: 1;
}

.text {
  margin-top: 1rem;
  max-width: 20rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.bold {
  text-align: center;
}
</style>
