<template>
  <div class="header-container" :style="{ color: textColor }">
    <h2>{{ name }}</h2>
    <div
      class="underline"
      :style="{ 'background-color': underlineColor }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#000'
    },
    underlineColor: {
      type: String,
      default: '#e68619'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.header-container {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
}

h2 {
  margin: 0;
  padding-bottom: 0.5rem;
  line-height: 1.5;
  word-break: break-word;
  text-align: center;
}

.underline {
  margin: 0 auto;
  height: 0.2rem;
  width: 60%;
}
</style>
