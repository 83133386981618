<template>
  <div class="card">
    <div class="image-container">
      <img :src="image" alt="Card image" class="card-image" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.card {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 0rem auto;
  max-width: 40rem;
  overflow: hidden;
  background-color: $white-gray;
  width: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  object-fit: cover;
}

.header-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(15, 12, 41, 0.9);
  color: #fff;
  padding: 0.5rem;
  text-align: center;
}

h4 {
  padding: 0;
  margin: 0;
}
</style>
