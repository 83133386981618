<template>
  <div :class="{ mobile: isMobile }" class="articles-container">
    <h3 class="article-h3-header" :class="{ mobile: isMobile }">
      Lista wszystkich artykułów:
    </h3>
    <div></div>
    <div
      v-for="article in articles"
      :key="article.art_id"
      class="article-section"
    >
      <h6 class="article-h6-header" :class="{ mobile: isMobile }">
        {{ article.art_header }}
      </h6>
      <div class="articles" :class="{ mobile: isMobile }">
        <div class="article-shortcud">
          <div v-if="isMobile">
            <img
              class="foto"
              :class="{ mobile: isMobile }"
              :src="article.art_img"
              :alt="article.header"
            />
          </div>

          <p v-html="truncateText(article.art_shortcut, 500)"></p>
          <div class="link-container">
            <div class="more" @click="selectArticle(article.art_id)">
              <div class="text-link">czytaj dalej...</div>
            </div>
          </div>
        </div>
        <div v-if="!isMobile">
          <img
            class="foto"
            :class="{ mobile: isMobile }"
            :src="article.art_img"
            :alt="article.header"
          />
        </div>
      </div>
    </div>
    <privacy-popup />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import privacyPopup from '../elements/PrivacyPopup.vue'

export default {
  components: {
    privacyPopup
  },
  name: 'ArticleList',
  computed: {
    ...mapGetters('articles', ['getArticles']),
    articles() {
      return this.getArticles
    },
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  },
  methods: {
    selectArticle(artId) {
      this.$router.push({ name: 'Article', params: { id: artId } })
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.article-section {
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.foto {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 5rem;
  alt: 'Zdjęcie artykułu';
}
.foto.mobile {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  width: 18rem;
  height: 18rem;
}

.article-h6-header.mobile {
  margin-top: 8rem;
  margin-bottom: -2rem;
}

.article-h3-header.mobile {
  margin-bottom: -6rem;
}

.articles-container {
  max-width: $page-width;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.articles {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: start;
  gap: 6rem;
  max-width: $page-width;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 5rem;
}

.articles.mobile {
  grid-template-columns: 1fr;
  margin-left: 1rem;
  margin-right: 1rem;
  gap: 0rem;
}

p,
h6 {
  margin-top: 0rem;
  max-width: 35rem;
}

h3,
h4,
h5 {
  color: $main-color;
}
</style>
