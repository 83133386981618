<template>
  <div
    :class="['card', { 'active-card': active }]"
    :style="{ backgroundColor: backgroundColor }"
  >
    <div v-if="image" class="image-container">
      <img v-if="image" :src="image" alt="Card image" class="card-image" />
      <div v-if="header" class="header-overlay">
        <h4>{{ header }}</h4>
      </div>
    </div>
    <div
      v-else
      :class="[
        'image-container-none',
        { 'image-container-none-duble': headerDublle }
      ]"
    >
      <div v-if="header" class="header-overlay">
        <h4>{{ header }}</h4>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    headerDublle: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#d6d4d1'
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.card {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 0rem auto;
  height: 100%;
  overflow: hidden;
}
.active-card {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 0rem auto;

  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
}

.header-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(15, 12, 41, 0.9);
  color: #e9e7e7;
  padding: 0.5rem;
  text-align: center;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 1rem;
}

.image-container-none {
  position: relative;

  width: 100%;
  min-height: 3rem;
  margin-bottom: 1rem;
  .header-overlay {
    position: relative;
  }
}

.image-container-none-duble {
  position: relative;
  width: 100%;

  margin-bottom: 1rem;
  .header-overlay {
    position: relative;
  }
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h4 {
  padding: 0 2rem;
  margin: 0;
}
</style>
