<template>
  <div class="consultations-container" :class="{ mobile: isMobile }">
    <div class="header-container">
      <base-header :name="'Konsultacje'" :textColor="'#202020'"></base-header>
    </div>

    <div class="consultation-description" :class="{ mobile: isMobile }">
      <base-photo-card v-if="!isMobile" class="card" :image="martynka">
      </base-photo-card>
      <base-card
        class="card description"
        :class="{ mobile: isMobile }"
        :header="'W czym mogę Ci pomóc?'"
        :backgroundColor="'#e9e7e7'"
      >
        <p class="text-description" :class="{ mobile: isMobile }">
          Pomagam przedsiębiorcom i managerom z branży gastronomicznej w
          <strong>efektywnym zarządzaniu zespołem</strong>, rozwijaniu
          <strong>kompetencji liderskich</strong> oraz
          <strong>zwiększaniu sprzedaży i rentowności firmy</strong>.
          Specjalizuję się w <strong>analizie</strong>,
          <strong>strategii</strong> oraz usprawnianiu
          <strong>procesów</strong> i <strong>procedur</strong>, dzięki czemu
          Twoja firma może działać sprawniej i osiągać lepsze rezultaty. Jeśli
          masz problem z poukładaniem firmy lub bieżącymi wyzwaniami w
          zarządzaniu – wspólnie znajdziemy rozwiązanie. Ze mną stworzysz
          najlepszą wersję swojej firmy.
        </p>

        <p class="text-description" :class="{ mobile: isMobile }">
          Jeśli dopiero planujesz otwarcie własnego lokalu gastronomicznego,
          pomogę Ci w opracowaniu unikalnej koncepcji i strategii. Razem
          przeanalizujemy, jak przekształcić Twój pomysł w dochodowy biznes,
          unikając najczęstszych błędów i budując solidne fundamenty pod rozwój
          Twojego gastrobiznesu.
        </p>
      </base-card>
      <base-card
        class="card"
        :class="{ mobile: isMobile }"
        :header="'Darmowa konsultacja'"
        :backgroundColor="'#e9e7e7'"
        :active="true"
        :image="consultations1"
        @click="
          openConsultationsPopup(
            'Darmowa konsultacja',
            'Zamów darmową konsultację. Pozostaw swoje dane a skontaktuję się z Tobą i ustalimy szczegóły',
            freeConsultations,
            consultations1
          )
        "
      >
        <ul class="list">
          <li>Czas trwania: 15 minut</li>
          <li>Cena: Darmowe</li>
        </ul>
        <p class="text" :class="{ mobile: isMobile }">
          W trakcie 15-minutowej darmowej konsultacji dokonam diagnozy Twoich
          wyzwań, zidentyfikuję kluczowe problemy oraz wskażę obszary do poprawy
          i możliwości rozwoju. To krótka rozmowa, która pokaże, jak mogę pomóc
          w rozwoju Twojego biznesu.
        </p>

        <div v-if="isMobile" class="button-container">
          <button
            class="description-button"
            :class="{ mobile: isMobile }"
            @click="
              openConsultationsPopup(
                'Darmowa konsultacja',
                'Zamów darmową konsultację. Pozostaw swoje dane a skontaktuję się z Tobą i ustalimy szczegóły',
                freeConsultations,
                consultations1
              )
            "
          >
            Dowiedz się więcej!
          </button>
        </div>
      </base-card>
      <base-card
        class="card"
        :class="{ mobile: isMobile }"
        :header="'Godzinna Konsultacja'"
        :backgroundColor="'#e9e7e7'"
        :image="consultations2"
        :active="true"
        @click="
          openConsultationsPopup(
            'Godzinna Konsultacja',
            'Tutaj należy napisać wstęp do informacji',
            consultations,
            consultations2
          )
        "
      >
        <ul class="list">
          <li>Czas trwania: 1 godzina</li>
          <li>cena: 300zł</li>
        </ul>
        <p class="text" :class="{ mobile: isMobile }">
          W trakcie godzinnej konsultacji szczegółowo omówimy Twoje wyzwania,
          zidentyfikuję kluczowe problemy i wskażę konkretne rozwiązania oraz
          praktyczne wskazówki do wdrożenia.
        </p>
        <p class="text" :class="{ mobile: isMobile }">
          Otrzymasz plan działania, który pomoże rozwijać Twój gastrobiznes,
          oraz pisemne podsumowanie ustaleń z dodatkowymi rekomendacjami.
        </p>
        <p class="text" :class="{ mobile: isMobile }">
          To godzina intensywnej pracy nad Twoim biznesem, która przyniesie
          wymierne rezultaty. Po zamówieniu konsultacji skontaktuję się z Tobą,
          aby ustalić dogodny termin.
        </p>

        <div v-if="isMobile" class="button-container">
          <button
            class="description-button"
            :class="{ mobile: isMobile }"
            @click="
              openConsultationsPopup(
                'Godzinna Konsultacja',
                'Tutaj należy napisać wstęp do informacji',
                consultations,
                consultations2
              )
            "
          >
            Dowiedz się więcej!
          </button>
        </div>
      </base-card>
      <base-card
        class="card"
        :class="{ mobile: isMobile }"
        :header="'Audyt tajemniczego gościa'"
        :backgroundColor="'#e9e7e7'"
        :image="audit"
        :active="true"
        @click="
          openConsultationsPopup(
            shortAudit('Audyt tajemniczego gościa'),
            'Poniżej znajdziesz przebieg audytu',
            audit_text,
            audit,
            audit_text2
          )
        "
      >
        <ul class="list">
          <li>Cena: 990zł + Koszt dojazdu</li>
        </ul>
        <p class="text" :class="{ mobile: isMobile }">
          <strong>Audyt tajemniczego gościa</strong> to profesjonalne narzędzie
          służące ocenie jakości obsługi oraz ogólnego doświadczenia gościa w
          restauracji. Audytor odwiedza Twój lokal jako anonimowy gość, aby
          sprawdzić kluczowe aspekty jego funkcjonowania, takie jak: jakość
          obsługi, jakość serwowanych posiłków, umiejętności sprzedażowe, czas
          oczekiwania, atmosfera, czystość oraz ogólne doświadczenie.
        </p>

        <div v-if="isMobile" class="button-container">
          <button
            class="description-button"
            :class="{ mobile: isMobile }"
            @click="
              openConsultationsPopup(
                'Audyt tajemniczego gościa',
                'Poniżej znajdziesz przebieg audytu',
                audit_text,
                audit,
                audit_text2
              )
            "
          >
            Dowiedz się więcej!
          </button>
        </div>
      </base-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RemoteServicesComponent',
  data() {
    return {
      martynka: require('@/assets/pictures/martynkas-pictures/17asmall.jpg'),
      consultations1: require('@/assets/pictures/other/14small.jpg'),
      consultations2: require('@/assets/pictures/other/16sma.jpg'),
      audit: require('@/assets/pictures/other/alaksiej-carankievic-Fmgqo-hB6j4-unsplash-small.jpg'),
      freeConsultations:
        '<ul class="list"><li>Czas trwania: 15 minut</li><li>Cena: Darmowe</li></ul> <p class="text" :class="{ mobile: isMobile }"> W trakcie 15-minutowej darmowej konsultacji dokonam diagnozy Twoich wyzwań, zidentyfikuję kluczowe problemy oraz wskażę obszary do poprawyi możliwości rozwoju. To krótka rozmowa, która pokaże, jak mogę pomóc  w rozwoju Twojego biznesu.</p>',
      consultations:
        '<p class="text" :class="{ mobile: isMobile }">W trakcie godzinnej konsultacji szczegółowo omówimy Twoje wyzwania, zidentyfikuję kluczowe problemy i wskażę konkretne rozwiązania oraz praktyczne wskazówki do wdrożenia.</p><p class="text" :class="{ mobile: isMobile }">  Otrzymasz plan działania, który pomoże rozwijać Twój gastrobiznes, oraz pisemne podsumowanie ustaleń z dodatkowymi rekomendacjami.</p><p class="text" :class="{ mobile: isMobile }">To godzina intensywnej pracy nad Twoim biznesem, która przyniesie wymierne rezultaty. Po zamówieniu konsultacji skontaktuję się z Tobą, aby ustalić dogodny termin.</p>',
      audit_text:
        '<strong class="audit-heder" style="text-aligne: center">Badanie potrzeb:</strong><p class="audit-text" style="margin-top: 0rem">Przed przystąpieniem do audytu ustalamy kryteria oceny, które firma zlecająca chce poddać analizie. Mogą to być takie aspekty, jak jakość obsługi, czas oczekiwania na posiłki, czystość lokalu, jakość jedzenia, atmosfera, umiejętności sprzedażowe personelu czy przestrzeganie standardów bezpieczeństwa żywności.</p><strong class="audit-heder">Wizyta tajemniczego gościa:</strong><p class="audit-text" style="margin-top: 0rem">Audytor odwiedza Twój lokal jako zwykły klient, nie ujawniając swojej tożsamości. Składa zamówienie, wchodzi w interakcję z obsługą oraz korzysta z oferowanych usług.</p><strong class="audit-heder">Obserwacja i ocena:</strong><p class="audit-text" style="margin-top: 0rem">Audytor podczas wizyty ocenia różne elementy, takie jak szybkość i organizacja pracy obsługi, uprzejmość personelu, budowanie relacji z gościem, jakość jedzenia, czystość łazienek, atmosferę oraz wystrój lokalu. Wszystko odbywa się zgodnie z wcześniej ustalonymi kryteriami oceny.</p><strong class="audit-heder" >Raportowanie:</strong><p class="audit-text" style="margin-top: 0rem">Po zakończeniu wizyty sporządzamy raport, który zawiera szczegółowe informacje na temat ocenianych kryteriów oraz sugestie dotyczące poprawy jakości usług i produktów.</p> <strong class="audit-heder">Analiza i działania naprawcze:</strong><p class="audit-text" style="margin-top: 0rem">Omawiamy z firmą zlecającą raport oraz proponowane działania naprawcze, które mogą zwiększyć jakość usług i poprawić doświadczenie gości.',
      audit_text2: '<ul class="list"><li>Cena: 990zł + Koszt dojazdu</li></ul>'
    }
  },
  components: {},

  methods: {
    shortAudit(audit) {
      if (this.isMobile) {
        audit = 'Audyt'
      }
      return audit
    },
    openConsultationsPopup(
      header,
      description,
      htmlContent,
      picture,
      htmlContent2
    ) {
      this.$emit('openConsultationsPopup', {
        header,
        description,
        htmlContent,
        picture,
        htmlContent2
      })
    },
    async selectService(service) {
      await this.$router.push({ name: 'Services', params: { id: service } })
      await this.$store.dispatch('updateSection', 'top')
      await this.$store.dispatch('updateSection', 'defoult')
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.consultation-description {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 70rem;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.consultation-description.mobile {
  grid-template-columns: 1fr;
}

.description {
  grid-column: span 2;
}

.consultations-container {
  margin-top: 5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consultations-container.mobile {
  padding: 0;
}

.header-container {
  margin-top: 2rem;
  width: 100%;
}

.card.mobile {
  grid-column: span 1;
}

.text {
  margin-top: 1rem;
  max-width: 20rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.text-description {
  margin-top: 1rem;
  max-width: 40rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.text-description.mobile {
  margin-left: 1rem;
  margin-right: 1rem;
}

.list {
  margin-top: 1rem;
  max-width: 20rem;
  margin-left: 2rem;
  margin-right: 3rem;
}

.description-button {
  background-color: $secendary-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $secendary-color-dark;
  border: none;
  cursor: pointer;
  color: $white;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.1;
  }
}

.button-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
</style>
