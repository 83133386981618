<template>
  <svg width="0" height="0">
    <defs>
      <clipPath id="wavy-path5" clipPathUnits="objectBoundingBox">
        <path d="M0,0 Q0.1,0.8 0.4,0.5 T1,0 Z" />
      </clipPath>
    </defs>
  </svg>
  <div class="services-container">
    <div class="nav-background"></div>
    <div class="services">
      <div class="top-background"></div>
      <div class="header-container"></div>
      <h3 ref="top" :class="{ 'mobile-header': isMobile }">
        {{ data.header }}
      </h3>
      <h5 class="sample" :class="{ mobile: isMobile }">Przykładowe zadania:</h5>
      <div class="services-cards-container" :class="{ mobile: isMobile }">
        <div v-for="service in data.services" :key="service" class="cell">
          <img class="tick" src="@/assets/elements/tick.svg" />
          <div class="card">
            {{ service }}
          </div>
        </div>
      </div>

      <servicesPopup ref="servicesPopup" />
      <div
        v-if="data.header !== 'Oferta Indywidualna'"
        class="services-grid"
        :class="{ mobile: isMobile }"
      >
        <div class="service-card">
          <h5>{{ data.pricing.small.price }}</h5>
          <div class="card-line" :class="{ mobile: isMobile }"></div>

          <p>{{ data.pricing.small.package }}</p>

          <button
            class="services-button"
            :class="{ mobile: isMobile }"
            @click="openServicesPopup(data.header, 'small')"
          >
            Zamów!
          </button>
        </div>
        <div class="service-card menager">
          <h5>{{ data.pricing.medium.price }}</h5>
          <div class="card-line" :class="{ mobile: isMobile }"></div>

          <p>{{ data.pricing.medium.package }}</p>

          <button
            class="services-button"
            :class="{ mobile: isMobile }"
            @click="openServicesPopup(data.header, 'medium')"
          >
            Zamów!
          </button>
        </div>
        <div class="service-card">
          <h5>{{ data.pricing.large.price }}</h5>
          <div class="card-line" :class="{ mobile: isMobile }"></div>

          <p>{{ data.pricing.large.package }}</p>

          <button
            class="services-button"
            :class="{ mobile: isMobile }"
            @click="openServicesPopup(data.header, 'large')"
          >
            Zamów!
          </button>
        </div>
      </div>
      <div v-else>
        <div class="service-card-other">
          <h5>Indywidualna wycena</h5>
          <div class="card-line" :class="{ mobile: isMobile }"></div>

          <p>
            Stawka godzinowa dobierana indywidualnie w zależności od zakresu
            obowiązków
          </p>

          <button
            class="services-button"
            :class="{ mobile: isMobile }"
            @click="openServicesPopup(data.header, 'large')"
          >
            Zamów!
          </button>
        </div>
      </div>
    </div>
    <div
      class="link-container"
      to="/remote-services"
      @click="changeSection('top', '/')"
    >
      <div class="text-link">Powrót...</div>
    </div>

    <privacy-popup />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
import { mapState } from 'vuex'
import servicesPopup from '@/components/elements/ServicesPopup.vue'
import privacyPopup from '@/components/elements/PrivacyPopup.vue'
export default {
  name: 'RecruitmentStrategiesArticle',
  metaInfo() {
    return {
      title: this.data.header,
      meta: [
        {
          name: 'description',
          content: this.article.art_description
        },
        {
          name: 'keywords',
          content: this.article.art_keywords
        }
      ]
    }
  },
  components: {
    servicesPopup,
    privacyPopup
  },
  data() {
    return {
      isMenuOpen: false,

      allData: {
        menager: {
          header: 'Menedżer Online',
          services: [
            'Wybór dostawców i negocjowanie warunków umów, regularna analiza cen',
            'Analiza trendów rynkowych, konkurencji i preferencji klientów',
            'Bieżąca analiza kosztów',
            'Współpraca z szefem kuchni w celu opracowywania receptur dań',
            'Wsparcie w organizacji wydarzeń i eventów w lokalu',
            'Kontrola nad umowami pracowniczymi i aktualizacją badań sanepidowskich, lekarskich, BHP',
            'Przygotowywanie comiesięcznych raportów finansowych (zestawienie kosztów i zysków)',
            'Analiza i optymalizacja wyników sprzedaży',
            'Identyfikowanie obszarów do poprawy i wdrażanie odpowiednich działań',
            'Analiza menu pod kątem inżynierii menu',
            'Monitorowanie płatności i rozliczeń (zestawienia płatności)',
            'Analiza opinii klientów i wdrażanie działań poprawiających ich zadowolenie'
          ],
          pricing: {
            small: {
              price: '150zł/h',
              package: 'do 10 godzin miesięcznie'
            },
            medium: {
              price: '125zł/h',
              package: 'od 10 do 29 godzin miesięcznie'
            },
            large: {
              price: '100zł/h',
              package: 'od 30 godzin miesięcznie'
            }
          },
          description:
            'Menadżer Online to kompleksowa usługa wirtualnego menedżera gastronomii, oferująca wsparcie w zarządzaniu restauracją i optymalizacji procesów operacyjnych. Nasz menadżer online pomoże Ci w wyborze dostawców, negocjowaniu umów oraz regularnej analizie cen. Oferujemy bieżącą analizę kosztów i kontrolę foodcostu, współpracę z szefem kuchni w opracowywaniu receptur dań, a także planowanie i realizację działań marketingowych. Wsparcie w organizacji wydarzeń, kontrola nad umowami pracowniczymi i badaniami sanepidowskimi, przygotowywanie raportów finansowych, analiza wyników sprzedaży oraz identyfikowanie obszarów do poprawy to tylko niektóre z naszych usług. Nasza oferta obejmuje również analizę menu, monitorowanie przychodów i wydatków, rozliczenia płatności, analizę rynku i konkurencji, opinii klientów oraz procesów operacyjnych.',
          keywords:
            'menadżer online, wirtualny menadżer gastronomii, zarządzanie restauracją, analiza kosztów, kontrola foodcostu, negocjowanie umów, wybór dostawców, działania marketingowe, kampanie w mediach społecznościowych, organizacja eventów, umowy pracownicze, badania sanepidowskie, raporty finansowe, analiza sprzedaży, inżynieria menu, monitorowanie przychodów, monitorowanie wydatków, rozliczenia płatności, analiza rynku, analiza konkurencji, opinie klientów, usprawnienia operacyjne, receptury dań, promocje i oferty specjalne, menedżer restauracji online, wsparcie marketingowe, wirtualne zarządzanie gastronomią'
        },
        asystent: {
          header: 'Asystent Online',
          services: [
            'Przygotowywanie dokumentów do rozliczeń kadrowo-płacowych',
            'Wpisywanie faktur (stanów magazynowych) do POSa',
            'Tworzenie grafików pracy, zarządzanie czasem pracy',
            'Tworzenie instrukcji stanowiskowych, zakresów obowiązków, instrukcji i procedur dla pracowników',
            'Wystawianie faktur elektronicznych',
            'Przygotowywanie dokumentów dla księgowości (w tym raport kasowy dla sp. z o.o.)',
            'Monitorowanie opinii gości i odpisywanie na nie',
            'Tworzenie spisów inwentaryzacyjnych',
            'Tworzenie dokumentacji sanepidowskiej potrzebnej do kontroli'
          ],
          pricing: {
            small: {
              price: '80zł/h',
              package: 'do 10 godzin miesięcznie'
            },
            medium: {
              price: '75zł/h',
              package: 'od 10 do 29 godzin miesięcznie'
            },
            large: {
              price: '70zł/h',
              package: 'od 30 godzin miesięcznie'
            }
          },
          description:
            'Asystent Online to kompleksowa usługa wirtualnego asystenta gastronomii, oferująca wsparcie w codziennych zadaniach administracyjnych i organizacyjnych. Nasz asystent online zajmuje się przygotowywaniem dokumentów do rozliczeń kadrowo-płacowych, wpisywaniem faktur do POSa, tworzeniem grafików pracy oraz zarządzaniem czasem pracy. Oferujemy tworzenie instrukcji stanowiskowych, zakresów obowiązków, instrukcji i procedur dla pracowników, wystawianie faktur elektronicznych oraz przygotowywanie dokumentów dla księgowości. Nasza usługa obejmuje także monitorowanie opinii gości i odpowiadanie na nie, tworzenie spisów inwentaryzacyjnych oraz dokumentacji sanepidowskiej potrzebnej do kontroli.',
          keywords:
            'asystent online, wirtualny asystent gastronomii, administracja restauracji, rozliczenia kadrowo-płacowe, wpisywanie faktur, POS, grafiki pracy, zarządzanie czasem pracy, instrukcje stanowiskowe, zakresy obowiązków, procedury dla pracowników, faktury elektroniczne, dokumenty dla księgowości, raport kasowy, monitorowanie opinii gości, odpowiedzi na opinie, spisy inwentaryzacyjne, dokumentacja sanepidowska, kontrola sanepidowska, wirtualne zarządzanie gastronomią, wsparcie administracyjne, organizacja pracy, menedżer restauracji online, wsparcie księgowe, wirtualny asystent, obsługa klienta, kontrola magazynowa.'
        },
        other: {
          header: 'Oferta Indywidualna',
          services: [
            'Nieszablonowe podejście do rozwiązywania problemów',
            'Indywidualny cennik',
            'pełna elastyczność'
          ],
          pricing: {
            small: {
              price: '150zł/h',
              package: 'do 10 godzin miesięcznie'
            },
            medium: {
              price: 'Stawka godzinowa dobrana indywidualnie',
              package: 'od 10 do 29 godzin miesięcznie'
            },
            large: {
              price: '100zł/h',
              package: 'od 30 godzin miesięcznie'
            }
          },
          description:
            'Oferta Indywidualna to usługa dopasowana do specyficznych potrzeb każdego klienta, zapewniająca nieszablonowe podejście do rozwiązywania problemów oraz pełną elastyczność w zakresie działań. Oferujemy indywidualny cennik, dostosowany do specyfiki i wymagań Twojego biznesu. Niezależnie od wyzwań, nasza oferta zapewnia rozwiązania skrojone na miarę, aby wspierać rozwój Twojej gastronomii. Skorzystaj z naszej oferty indywidualnej, aby otrzymać usługi idealnie dostosowane do Twoich unikalnych potrzeb.',
          keywords:
            'oferta indywidualna, elastyczne usługi gastronomiczne, nieszablonowe rozwiązania, indywidualny cennik, pełna elastyczność, dostosowane usługi, wsparcie gastronomii, zarządzanie restauracją, rozwiązania na miarę, usługi dopasowane do klienta, wsparcie w rozwoju biznesu, unikalne potrzeby, indywidualne podejście, usługi gastronomiczne, oferta skrojona na miarę, dostosowane działania, rozwój gastronomii, wsparcie dla restauracji, usługi dla gastronomii.'
        }
      }
    }
  },
  props: ['id'],
  watch: {
    'section.section': {
      handler: async function (newValue) {
        await this.scrollTo(newValue)
        await this.$emit('closeMenu', true)
      },
      immediate: true
    },
    article: {
      handler: function () {
        this.updateMeta()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openServicesPopup(service, pack) {
      this.$refs.servicesPopup.openPopup(service, pack)
    },
    updateMeta() {
      useHead({
        title: this.data ? this.data.header : 'Loading...',
        meta: [
          {
            name: 'description',
            content: this.data ? this.data.description : 'Loading...'
          },
          {
            name: 'keywords',
            content: this.data ? this.data.keywords : 'Loading...'
          }
        ]
      })
    },
    scrollTo(ref) {
      if (this.$refs[ref] && this.$refs[ref].$el) {
        this.$refs[ref].$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      } else if (this.$refs[ref]) {
        this.$refs[ref].scrollIntoView({ behavior: 'auto', block: 'start' })
      }
    },
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    pdateSectionAndNavigate(section) {
      this.$store.commit('updateSection', section)
      this.$router.push('/')
    }
  },
  computed: {
    data() {
      return this.allData[this.id] || null
    },

    isMobile() {
      return this.$store.state.responsive.isMobile
    },
    ...mapState(['section'])
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes leftAndBounce {
  from {
    opacity: 0;
    transform: translateX(-130px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes painting {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.sample {
  margin-left: 2rem;
  margin-top: 8rem;
  color: $main-color;
}

.sample.mobile {
  margin-left: 0rem;
}
.top-background {
  position: absolute;
  left: 0;
  top: 0rem;
  width: 100%;
  height: 7.1rem;
  background-color: $main-color;
}

.header-container {
  position: absolute;
  left: 0;
  top: 10rem;
  padding-top: 0rem;
  margin-bottom: 3rem;
  background-color: $main-color;
  z-index: -1;
  padding-bottom: 8rem;
  clip-path: url(#wavy-path5);
  width: 100vw;
}

.mobile-header {
  margin-top: 0rem;
  margin-left: 0rem;
  font-size: 1.7rem;
}

.services-cards-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.services-cards-container.mobile {
  margin-left: -2rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
}

.cell {
  display: grid;
  grid-template-columns: auto auto;
}
.card {
  width: 13rem;
  position: relative;
  margin-bottom: 2rem;
}

.tick {
  width: 2rem;
  margin-left: 2rem;
  margin-right: 0rem;
}
.more {
  margin-top: 2rem;
}

h2,
h3,
h4,
h5 {
  color: $white;
  margin-top: 3rem;
}

h3 {
  margin-left: 4rem;
  text-transform: uppercase;
}

.nav-background {
  height: 4rem;
}
.services-container {
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.link-container {
  width: 100%;
}
.foto-container {
  width: 100%;
  text-align: center;
}

.foto {
  width: 25rem;
  height: 25rem;
  object-fit: cover;
  border-radius: 5px;
}

.foto.mobile {
  width: 18rem;
  height: 18rem;
}

.services {
  margin: 2rem;
}

.services-container {
  max-width: $page-width;
  margin-left: auto;
  margin-right: auto;
}

ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  position: relative;
  padding-left: 1.5em;
}
ul li::before {
  content: '\2713';
  position: absolute;
  left: 0;
  font-weight: bold;
}

.remote-services-container {
  margin-top: 5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remote-services-container.mobile {
  margin-top: 2rem;
  padding: 1rem;
}

.services-grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
}

.services-grid.mobile {
  grid-template-columns: 1fr;
}

.service-card {
  position: relative;
  background-color: #f5f5f5;
  background-color: $main-color-light;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0.2rem 0.2rem 0.3rem $gray-light;
  transition: transform 0.3s ease;
  padding-bottom: 8rem;

  &:hover {
    transform: translateY(-5px);
  }

  h5,
  h6 {
    margin-bottom: 1rem;
    color: $gray;
  }

  h5 {
    text-align: center;
  }
  h6 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  p,
  li {
    font-size: 1rem;
    text-align: center;
    text-indent: 0rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  li {
  }
}

.back {
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
}
.service-card-other {
  position: relative;
  background-color: #f5f5f5;
  background-color: $main-color-light;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0.2rem 0.2rem 0.3rem $gray-light;
  transition: transform 0.3s ease;
  padding-bottom: 8rem;
  margin-top: 3rem;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  h5,
  h6 {
    margin-bottom: 1rem;
    color: $gray;
  }

  h5 {
    text-align: center;
  }
  h6 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  p,
  li {
    font-size: 1rem;
    text-align: center;
    text-indent: 0rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  li {
  }
}

.services-button {
  background-color: $main-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;

  position: absolute;
  bottom: 4rem;
  left: 6rem;
  left: 50%;
  transform: translateX(-50%);

  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    transform: translateX(-50%) scale(1.1);
  }
}

.card-line {
  position: absolute;
  left: 4rem;
  width: calc(100% - 8rem);
  height: 3px;
  background-color: $gray;
}

.card-line.mobile {
  top: 7rem;
}
</style>
