<template>
  <div id="nav_bar">
    <div id="logo_container" v-if="!isMobile">
      <router-link to="/">
        <img
          alt="logo firmy"
          aria-label="Strona domowa"
          id="logo"
          :src="logoSrc"
        />
      </router-link>
    </div>

    <div class="hero-menu">
      <span
        class="nav-link"
        @click="changeSection('hero', '/')"
        active-class="active"
        >Strona Główna</span
      >
      <span
        class="nav-link"
        @click="changeSection('consultations', '/')"
        active-class="active"
        >Konsultacje</span
      >
      <span
        class="nav-link"
        @click="changeSection('courses', '/')"
        active-class="active"
        >Szkolenia</span
      >
      <span
        class="nav-link"
        @click="changeSection('podcast', '/')"
        active-class="active"
        >Podcast</span
      >

      <span
        class="nav-link"
        @click="changeSection('blog', '/')"
        active-class="active"
        >Blog</span
      >

      <span
        class="nav-link"
        @click="changeSection('contact', '/')"
        active-class="active"
        >Kontakt</span
      >
    </div>

    <label v-if="isMobile" @click="toggleMenu" id="menu-icon">&#9776;</label>

    <div id="mobile-menu" v-if="isMobile && isMenuOpen"></div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Strona Główna - Twoja platforma edukacyjna',
    meta: [
      {
        name: 'description',
        content:
          'Odkryj naszą platformę edukacyjną. Znajdziesz tu szkolenia, podcasty, blogi i wiele więcej, które pomogą Ci w rozwoju osobistym i zawodowym. Skorzystaj z naszych zasobów i rozwijaj swoje umiejętności.'
      },
      {
        name: 'keywords',
        content:
          'edukacja, szkolenia online, podcasty edukacyjne, blogi rozwojowe, kontakt, platforma edukacyjna'
      }
    ]
  },
  data() {
    return {
      logoSrc: require('@/assets/logo/04ws.png'),
      isMenuOpen: false
    }
  },
  methods: {
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    pdateSectionAndNavigate(section) {
      this.$store.commit('updateSection', section)
      this.$router.push('/')
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '../main.scss';

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

#logo {
  flex-basis: 100%;
  max-width: 100%;
  max-width: 3em;
  margin-top: 0.5rem;
}

#logo_conteiner {
}

.menu {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
}

#nav_bar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 4rem;
  max-width: $page-width;
  width: 100%;
}

a {
  white-space: nowrap;
  align-self: center;

  color: white;

  text-decoration: underline;
  text-underline-offset: 0.4rem;
  text-decoration: underline 0.4em rgba($main-color, 0);
  transition: text-decoration-color 1000ms;
  &:hover {
    text-decoration: underline 0.4em rgba($main-color, 1);
  }
}

#menu-icon {
  display: block;
  cursor: pointer;
  align-self: center;
}

#mobile-menu {
  display: block;
  position: absolute;

  left: 70px;
  top: 20px;
  background-color: $white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transform: translateY(0);
  animation: slideIn 0.3s ease-in-out forwards;
  a {
    color: black;
    text-decoration: none;
  }
}

.menu-items li {
  margin-left: 20px;
}

#mobile-header {
  align-self: center;
  margin-right: 20px;
}

.hero-menu {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  margin-right: 4rem;
}

#nav_bar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 4rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
}

.nav-link {
  font-family: 'Roboto-bold';
  cursor: pointer;
  margin-left: 50px;
  white-space: nowrap;
  align-self: center;
  color: white;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration: underline 0.4em rgba($main-color, 0);
  transition: text-decoration-color 1000ms;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline 0.4em rgba($main-color, 1);
  }
}

.hero-menu-line {
  position: absolute;
  top: 7rem;
  width: 100%;
  height: 2px;
  background-color: $main-color;
}
</style>
