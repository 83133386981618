<template>
  <div>
    <div ref="top"></div>
    <ArticleList @selectArticle="fetchArticleDetails" />
    <privacy-popup />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArticleList from './ArticleList.vue'
import PrivacyPopup from '../elements/PrivacyPopup.vue'

export default {
  name: 'AllArticlesComponent',
  components: {
    ArticleList,
    PrivacyPopup
  },
  data() {
    return {
      selectedArticle: null
    }
  },

  computed: {
    ...mapGetters('articles', ['getArticleById']),
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  },
  methods: {
    scrollTo(ref) {
      const behavior = this.isMobile ? 'auto' : 'smooth'
      if (this.$refs[ref] && this.$refs[ref].$el) {
        this.$refs[ref].$el.scrollIntoView({
          behavior: behavior,
          block: 'start'
        })
      } else if (this.$refs[ref]) {
        this.$refs[ref].scrollIntoView({ behavior: behavior, block: 'start' })
      }
    },
    fetchArticleDetails(artId) {
      this.selectedArticle = this.getArticleById(artId)
      this.scrollToTop()
    },
    scrollToTop() {
      if (this.$refs.top) {
        this.$refs.top.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    },
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    }
  },
  mounted() {
    this.scrollTo('top')
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.article-container {
  padding-bottom: 4rem;
}

.nav-background {
  height: 4rem;
}
</style>
