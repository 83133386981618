<template>
  <div v-if="isVisible" class="popup" @click.self="closePopup">
    <div class="popup-content" :class="{ mobile: isMobile }">
      <button class="close-button" @click="closePopup">&times;</button>
      <div class="popup-header">
        <h2>{{ header }}</h2>
        <p>{{ description }}</p>
      </div>
      <div class="content-grid" :class="{ mobile: isMobile }">
        <div
          class="html-content"
          :class="{ mobile: isMobile }"
          v-html="htmlContent"
        ></div>
        <div>
          <div class="spinner-container" v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <div v-else-if="message" class="message">
            <p>{{ this.message }}</p>
            <button class="ok-button" @click="closeMessage()">ok</button>
          </div>
          <div class="form-container" v-else>
            <img :src="picture" />
            <form @submit.prevent="submitForm" class="form-vertical">
              <label>
                <input
                  type="email"
                  v-model="email"
                  name="email"
                  placeholder="Wpisz swój email"
                  required
                />
              </label>
              <label>
                <input
                  v-model="phone"
                  name="Telefon"
                  placeholder="Wpisz swój numer telefonu"
                  required
                />
              </label>
              <label>
                <input
                  name="Imię"
                  v-model="name"
                  placeholder="Twoje imię"
                  required
                />
              </label>
              <button class="submit-button" type="submit">Zamów</button>
            </form>
          </div>
          <div
            v-if="htmlContent2"
            v-html="htmlContent2"
            class="html-content2"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isVisible: false,
      phone: '',
      email: '',
      name: '',
      isLoading: false,
      message: null,
      success: false,
      header: '',
      description: '',
      htmlContent: '',
      picture: '',
      description2: ''
    }
  },
  methods: {
    closeMessage() {
      if (this.success) {
        this.closePopup()
      }
      this.message = null
    },
    openPopup(payload) {
      this.isVisible = true
      ;(this.header = payload.header),
        (this.description = payload.description),
        (this.htmlContent = payload.htmlContent),
        (this.picture = payload.picture),
        (this.htmlContent2 = payload.htmlContent2)
    },
    closePopup() {
      this.message = null
      this.success = false
      this.isVisible = false
    },
    submited() {
      this.message = null
      this.message =
        'Dziękuję za zaufanie! Skontaktuję się z Tobą w najbiższym czasie i ustlimy szczgóły'
      setTimeout(() => {
        this.message = null
        this.closePopup()
      }, 5000)
    },
    async submitForm() {
      this.message = null
      const formData = new FormData()
      formData.append('Typ', 'Zapis na: ' + this.header)
      formData.append('telefon', this.phone)
      formData.append('email', this.email)
      formData.append('imię', this.name)
      this.isLoading = true
      try {
        const response = await fetch('https://formspree.io/f/xkggpbzk', {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json'
          }
        })

        if (response.ok) {
          this.email = ''
          this.name = ''
          this.phone = ''
          this.$gtag.event('form', {
            stage: 'send',
            form: 'newsletter',
            success: true
          })
          this.isLoading = false
          this.success = true
          this.submited()
        } else {
          this.message =
            'Wystąpił problem z przesłaniem formularza. Spróbuj ponownie.'
          this.$gtag.event('form', {
            stage: 'send',
            form: 'consultations',
            success: false
          })
          this.isLoading = false
        }
      } catch (error) {
        console.error('Error:', error)

        this.message =
          'Wystąpił problem z przesłaniem formularza. Spróbuj ponownie.'
        this.$gtag.event('form', {
          stage: 'send',
          form: 'consultations',
          success: false
        })
        this.isLoading = false
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  },
  mounted() {
    this.$gtag.event('form', {
      stage: 'enter',
      form: 'consultations'
    })
    this.success = false
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';
img {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  width: 15rem;
  height: 15rem;

  object-fit: cover;
  margin: 1rem auto;
}
.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content-grid.mobile {
  display: grid;
  grid-template-columns: 1fr;
}

.popup-header {
  width: 100%;
  background-color: $white-gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-color: $main-color;
  h2 {
    margin-top: 2rem;
    margin-bottom: 0rem;
    text-align: center;
  }
  p {
    text-indent: 0em;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.2rem;
    max-width: 30rem;
  }
}

.html-content {
  margin: 2rem;
}

.form-icon-container {
  background-color: $main-color;
  border-radius: 50%;
  padding: 0.3rem;
  margin-top: -2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-style: solid;
  border-color: $gray;
  border-width: 2px;
  svg {
    fill: $gray;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20000;
}

.popup-content {
  background: $white;
  padding: 0rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;

  width: 70rem;
  min-height: 40rem;
  position: relative;
}

.popup-content.mobile {
  width: 90%;
  height: 90%;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.message {
  position: relative;
  height: 12rem;
  display: flex;
  justify-content: center;
  text-align: center;

  p {
    font-size: 1.2rem;
    text-align: center;
    text-indent: 0em;
    max-width: 15rem;
    margin: 2rem;
  }
}

.ok-button {
  background-color: $main-color;
  padding: 0.3rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 1px 1px 1px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  margin-left: auto;
  margin-right: auto;
  width: 6rem;
  margin-bottom: 1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  &:hover {
    scale: 1.05;
  }
}

.spinner-container {
  height: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container {
  text-align: center;
  align-items: center;
}

.form-vertical {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 60%;

  margin-left: auto;
  margin-right: auto;
  padding: 0rem;
}

.form-vertical label {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.form-vertical input[type='email'],
.form-vertical input[name='Imię'],
.form-vertical input[name='Telefon'] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $gray;
  border-radius: 0.25rem;
  background-color: $white;
}

.submit-button {
  background-color: $main-color;
  padding: 0.3rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 1px 1px 1px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  &:hover {
    scale: 1.05;
  }
}

input {
  margin: 0 auto;
  max-width: 12rem;
  height: 0.8rem;
}

.audit-heder {
  text-align: center;
}

.audit-text {
  margin: 0;
}

.html-content {
  max-height: 25rem;
  overflow-y: auto;
  width: 30rem;
}

.html-content.mobile {
  margin: 1rem;
  width: 90%;
  overflow-y: hidden;
  max-height: 100%;
}

.html-content2 {
  margin-left: 3rem;
}
</style>
