<template>
  <div class="header-container" :class="{ mobile: isMobile }">
    <base-header :name="'Blog'" :textColor="'#202020'"></base-header>
  </div>
  <div class="blog-container" :class="{ mobile: isMobile }">
    <base-card
      @click="selectArticle(articles[0].art_id)"
      :active="true"
      :header="articles[0].art_header"
      :image="articles[0].art_img"
      :backgroundColor="'#e9e7e7'"
    >
      <div :class="{ mobile: isMobile }">
        <p class="text">{{ truncateText(articles[0].art_shortcut, 500) }}"</p>
      </div>
    </base-card>

    <base-card
      @click="selectArticle(articles[1].art_id)"
      :active="true"
      :header="articles[1].art_header"
      :image="articles[1].art_img"
      :backgroundColor="'#e9e7e7'"
    >
      <div :class="{ mobile: isMobile }">
        <p class="text">{{ truncateText(articles[1].art_shortcut, 500) }}</p>
      </div>
    </base-card>
  </div>

  <div class="link-container">
    <div class="more" to="/articles" @click="changeSection('top', '/articles')">
      <div class="text-link">Zobacz wszyskie artykuły...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BlogContainer',
  metaInfo: {
    title: 'Blog - Najnowsze Artykuły o Gastronomii i Zarządzaniu Restauracją',
    meta: [
      {
        name: 'description',
        content:
          'Odkryj najnowsze trendy i strategie w gastronomii. Przeczytaj nasze artykuły, które pomogą Ci dostosować się do zmieniającego się świata restauracji, zwiększyć sprzedaż i osiągnąć sukces w branży.'
      },
      {
        name: 'keywords',
        content:
          'gastronomia, zarządzanie restauracją, zwiększanie sprzedaży w restauracji, nowe trendy w gastronomii, blog gastronomiczny'
      }
    ]
  },
  data() {
    return {
      isHeaderVisible: false
    }
  },
  mounted() {},
  methods: {
    async selectArticle(artId) {
      await this.$router.push({ name: 'Article', params: { id: artId } })
      await this.$store.dispatch('updateSection', 'top')
      await this.$store.dispatch('updateSection', 'defoult')
    },
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    },

    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    },
    ...mapGetters('articles', ['getLatestArticles']),
    articles() {
      return this.getLatestArticles
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.header-container {
  margin-top: 6rem;
  width: 100%;
}

.header-container.mobile {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

h6 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.header-container {
}

.article-shortcud {
  margin-bottom: 2rem;
  opacity: 0;
}

.article-shortcud.mobile {
  opacity: 1;
}
.article-shortcud.animated {
  animation: fadeIn 1s forwards 0.5s;
}

.article-header.mobile {
  margin-bottom: -1rem;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
}
.blog-container.mobile {
  grid-template-columns: 1fr;
}

.element4 {
  order: 2;
}

.foto {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 5rem;
}

.foto.mobile {
  margin-left: 2rem;
  margin-top: 1rem;
}
.info.animated {
  animation: fadeIn 1s forwards 0.5s;
}

p {
  text-indent: 1.5em;
  margin-top: 2rem;
  max-width: 32rem;
}

.info {
  position: relative;
  opacity: 0;
}
.button-container {
  margin-top: 5rem;
  width: 100%;
  text-align: center;
}

.bottm-link {
  left: 0rem;
  bottom: -2rem;
}

.more {
  text-decoration: none;
}

.info.mobile {
  opacity: 1;
}

.text {
  margin-top: 1rem;
  max-width: 20rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.link-container {
  margin-top: 3rem;
}
</style>
