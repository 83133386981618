import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import AllArticlesComponent from '@/components/articles/AllArticlesCoponent.vue'

import ArticleComponent from '@/components/articles/ArticleComponent.vue'
import ServicesComponent from '@/components/ServicesComponent.vue'
import CoursesComponent from '@/components/CoursesComponent.vue'

const routes = [
  { path: '/:section?', name: 'Home', component: HomePage, props: true },
  { path: '/articles', component: AllArticlesComponent },

  {
    path: '/article/:id',
    name: 'Article',
    component: ArticleComponent,
    props: true
  },
  {
    path: '/services/:id',
    name: 'Services',
    component: ServicesComponent,
    props: true
  },
  {
    path: '/consultations/:id',
    name: 'Consultations',
    component: CoursesComponent,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  

  const channel = to.query.c
  if (channel) {
    setTimeout(() => {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'channel', {
          event_category: 'distribution',
          event_label: channel
        })
      }
    }, 1000)

    next({
      path: to.path,
      query: {}
    })
  } else {
    next()
  }
})

export default router
