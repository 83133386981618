<template>
  <div class="header-container" :class="{ mobile: isMobile }">
    <base-header :name="'Kontakt'" :textColor="'#202020'"></base-header>
  </div>

  <div class="contact-container" :class="{ mobile: isMobile }">
    <base-photo-card :image="martynka"></base-photo-card>
    <base-card class="contact-card-container" :header="'Formularz kontaktowy'">
      <div class="contact-card" :class="{ mobile: isMobile }">
        <div>
          <p class="text" :class="{ mobile: isMobile }">
            Jeśli chcesz się dowiedzieć więcej o mojej działalność, zapraszam na
            mój podcast lub bloga, a jeśli chcesz się ze mną skontaktować,
            możesz napisać mi maila, lub wysłać wiadomość przez formularz. Na
            wszystkie pytania odpowiem najszybciej, jak się da.
          </p>

          <div class="link-container text" :class="{ mobile: isMobile }">
            <span class="text-link">kontakt@odstresudogastrobiznesu.pl </span>
          </div>
        </div>

        <div class="popup-content" :class="{ mobile: isMobile }">
          <div class="spinner-container" v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <div v-else-if="message" class="message">
            <p>{{ this.message }}</p>
            <button class="ok-button" @click="closeMessage()">ok</button>
          </div>
          <div class="form-container" :class="{ mobile: isMobile }" v-else>
            <form @submit.prevent="submitForm" class="form-vertical">
              <label>
                <input
                  type="email"
                  v-model="email"
                  name="email"
                  placeholder="Wpisz swój email"
                  required
                />
              </label>
              <label>
                <textarea
                  name="Wiadomość"
                  v-model="form_message"
                  placeholder="Wiadomość"
                  required
                ></textarea>
              </label>
              <button class="submit-button" type="submit">Zapisz się</button>
            </form>
          </div>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Kontakt - Skontaktuj się z nami już dziś!',
    meta: [
      {
        name: 'description',
        content:
          'Masz pytania? Skontaktuj się z nami, aby dowiedzieć się więcej o naszych usługach. Napisz do nas e-mail lub wypełnij formularz kontaktowy na naszej stronie. Odpowiemy na wszystkie Twoje pytania!'
      },
      {
        name: 'keywords',
        content:
          'kontakt, formularz kontaktowy, e-mail kontaktowy, skontaktuj się, wsparcie, serwis klienta'
      }
    ]
  },
  components: {},
  data() {
    return {
      isHeaderVisible: false,
      martynka: require('@/assets/pictures/martynkas-pictures/02squere.jpg'),
      form_message: '',
      isVisible: false,
      email: '',
      name: '',
      isLoading: false,
      message: null,
      success: false
    }
  },
  mounted() {},
  methods: {
    openSubscriptionPopup() {
      this.$refs.subscriptionPopup.openPopup()
    },
    closeMessage() {
      if (this.success) {
        this.closePopup()
      }
      this.message = null
    },
    openPopup() {
      this.isVisible = true
    },
    closePopup() {
      this.message = null
      this.success = false
      this.isVisible = false
    },
    submited() {
      this.message = null
      this.message =
        'Twoja wiadomość została wysłana! Odpowiem na nią najszybciej jak się da'
      setTimeout(() => {
        this.message = null
        this.closePopup()
      }, 5000)
    },
    async submitForm() {
      this.message = null

      const formData = new FormData()
      formData.append('Typ', 'Wiadomość')
      formData.append('email', this.email)
      formData.append('wiadomość', this.form_message)
      this.isLoading = true
      try {
        const response = await fetch('https://formspree.io/f/xjvnjzde', {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json'
          }
        })

        if (response.ok) {
          this.email = ''
          this.form_message = ''

          this.$gtag.event('form', {
            stage: 'send',
            form: 'contact',
            success: true
          })
          this.isLoading = false
          this.success = true
          this.submited()
        } else {
          alert('Wystąpił problem z przesłaniem formularza.')

          this.$gtag.event('form', {
            stage: 'send',
            form: 'contact',
            success: false
          })
        }
      } catch (error) {
        console.error('Error:', error)
        alert('Wystąpił błąd podczas przesyłania formularza.')

        this.$gtag.event('form', {
          stage: 'send',
          form: 'contact',
          success: false
        })
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';
.header-container {
  margin-top: 2rem;
  width: 100%;
}

.header-container.mobile {
  margin-top: 0rem;
  width: 100%;
}

.contact-card-container {
  grid-column: span 2;
}

.contact-card-container.mobile {
  grid-column: span 1;
  max-width: 90%;
}

.contact-card {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contact-card.mobile {
  grid-template-columns: 1fr;
}

.header-container.mobile {
  margin-bottom: 2rem;
}

.link-container {
  justify-content: flex-start;
}

.contact-button-container {
  display: flex;
  margin-top: 1rem;
}

.contact-button-container.mobile {
  display: inline;
  margin-top: 1rem;
}
.contact-button {
  background-color: $main-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;
  margin-left: 1rem;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.05;
  }
}

.contact-button.mobile {
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
}

.foto {
  width: 25rem;
  height: 25rem;
  object-fit: cover;
  object-position: 50% 30%;
  border-radius: 5px;
}

.foto.mobile {
  width: 20rem;
  height: 20rem;
  margin-left: 0.5rem;
}

.contact-container.mobile {
  grid-template-columns: 1fr;
  gap: 1rem 0;
}

.info.animated {
  animation: fadeIn 1s forwards 0.5s;
}

.text {
  margin-top: 1rem;
  max-width: 20rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.text.mobile {
  margin-left: 1rem;
  margin-right: 1rem;
}
.or {
  margin-top: 0.4em;
}

.info {
  position: relative;
  opacity: 0;
}

.or.mobile {
  margin-top: 2rem;
  text-align: center;
}

.info.mobile {
  opacity: 1;
}

.popup-content {
  .popup-header {
    width: 100%;
    background-color: $white-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: $main-color;
    h2 {
      margin-top: 0.2rem;
      margin-bottom: 0rem;
    }
    p {
      text-indent: 0em;
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 0.2rem;
      max-width: 25rem;
    }
  }

  .form-icon-container {
    background-color: $main-color;
    border-radius: 50%;
    padding: 0.3rem;
    margin-top: -2rem;
    width: 2.5rem;
    height: 2.5rem;
    border-style: solid;
    border-color: $gray;
    border-width: 2px;
    svg {
      fill: $gray;
    }
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 20000;
  }

  .popup-content {
  }

  .popup-content.mobile {
    width: 90%;
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }

  .message {
    position: relative;
    height: 12rem;
    display: flex;
    justify-content: center;
    text-align: center;

    p {
      font-size: 1.2rem;
      text-align: center;
      text-indent: 0em;
      max-width: 15rem;
    }
  }

  .ok-button {
    background-color: $main-color;
    padding: 0.3rem 1.5rem;
    border-radius: 2.5rem;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    box-shadow: 1px 1px 1px 0px $main-color-dark;
    border: none;
    cursor: pointer;
    color: $gray;
    transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
    margin-left: auto;
    margin-right: auto;
    width: 6rem;
    margin-bottom: 1.2rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    &:hover {
      scale: 1.05;
    }
  }

  .spinner-container {
    height: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form-container {
    height: 13rem;
  }

  .form-container.mobile {
    height: 100%;
  }

  .form-vertical {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
  }

  .form-vertical label {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .form-vertical input[type='email'],
  .form-vertical input[name='Imię'] {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $gray;
    border-radius: 0.25rem;
    background-color: $white;
  }

  .form-vertical textarea[name='Wiadomość'] {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $gray;
    border-radius: 0.25rem;
    background-color: $white;
    height: 6rem;
  }

  .submit-button {
    background-color: $main-color;
    padding: 0.3rem 1.5rem;
    border-radius: 2.5rem;
    font-family: 'Roboto-Bold';
    text-transform: uppercase;
    box-shadow: 1px 1px 1px 0px $main-color-dark;
    border: none;
    cursor: pointer;
    color: $gray;
    transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    margin-top: 0.5rem;
    &:hover {
      scale: 1.05;
    }
  }
}
</style>
