<template>
  <div class="article-container" v-if="article">
    <div ref="top" class="nav-background"></div>
    <div class="link-container">
      <div
        class="more"
        to="/articles"
        @click="changeSection('top', '/articles')"
      >
        <div class="text-link">Zobacz wszyskie artykuły...</div>
      </div>
    </div>
    <div class="article">
      <h4>{{ article.art_header }}</h4>
      <div class="foto-container">
        <img
          :src="article.art_img"
          alt="Article Image"
          class="foto"
          :class="{ mobile: isMobile }"
        />
      </div>
      <div v-html="article.art_text"></div>
      <div class="link-container">
        <div
          class="more central-link-container"
          to="/articles"
          @click="changeSection('top', '/articles')"
        >
          <div class="text-link central-link">Zobacz wszyskie artykuły...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Select an article to view its details.</p>
    <privacy-popup />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { useHead } from '@vueuse/head'
import privacyPopup from '../elements/PrivacyPopup.vue'
export default {
  components: {
    privacyPopup
  },
  name: 'RecruitmentStrategiesArticle',
  metaInfo() {
    return {
      title: this.article.art_header,
      meta: [
        {
          name: 'description',
          content: this.article.art_description
        },
        {
          name: 'keywords',
          content: this.article.art_keywords
        }
      ]
    }
  },
  data() {
    return {
      isMenuOpen: false
    }
  },
  props: ['id'],
  watch: {
    'section.section': {
      handler: async function (newValue) {
        await this.scrollTo(newValue)
        await this.$emit('closeMenu', true)
      },
      immediate: true
    },
    article: {
      handler: function () {
        this.updateMeta()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateMeta() {
      useHead({
        title: this.article ? this.article.art_header : 'Loading...',
        meta: [
          {
            name: 'description',
            content: this.article ? this.article.art_description : 'Loading...'
          },
          {
            name: 'keywords',
            content: this.article ? this.article.art_keywords : 'Loading...'
          }
        ]
      })
    },
    scrollTo(ref) {
      if (this.$refs[ref] && this.$refs[ref].$el) {
        this.$refs[ref].$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      } else if (this.$refs[ref]) {
        this.$refs[ref].scrollIntoView({ behavior: 'auto', block: 'start' })
      }
    },
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    pdateSectionAndNavigate(section) {
      this.$store.commit('updateSection', section)
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters('articles', ['getArticleById']),
    article() {
      return this.getArticleById(parseInt(this.id))
    },

    isMobile() {
      return this.$store.state.responsive.isMobile
    },
    ...mapState(['section'])
  },
  created() {
    if (!this.article) {
      this.loadArticle(this.id)
    }
    this.updateMeta()
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes leftAndBounce {
  from {
    opacity: 0;
    transform: translateX(-130px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes painting {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.more {
  margin-top: 2rem;
}

h2,
h3,
h4,
h5 {
  color: $main-color;
}

.nav-background {
  height: 4rem;
}
.article-container {
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.foto-container {
  width: 100%;
  text-align: center;
}

.foto {
  width: 25rem;
  height: 25rem;
  object-fit: cover;
  border-radius: 5px;
}

.foto.mobile {
  width: 18rem;
  height: 18rem;
}

.article {
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
}

.article-container {
  max-width: $page-width;
  margin-left: auto;
  margin-right: auto;
}
</style>
