<template>
  <div class="footer">
    <div>
      <p>Progres Martyna Więckowiak</p>
      <p>Mickiewicza 9D, 58-320 Walim</p>
      <p>Nip: 6222735521</p>
      <p>Regon: 529202151</p>
    </div>
    <div>
      <p>Ważne linki:</p>
      <p>
        <a
          href="https://odstresudogastrobiznesu.pl/polityka-prywatno%C5%9Bci-progres.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Polityka Prywatnośći
        </a>
      </p>
      <p>
        <a
          href="https://odstresudogastrobiznesu.pl/polityka-cookies-progres.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Polityka Cookies
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import '@/main.scss';
.footer {
  display: grid;
  grid-template-columns: auto auto;
  div {
    text-align: center;
  }
}

p {
  font-size: $text-small;
  color: white;
}
header {
  margin-bottom: 20px;
}
a {
  font-size: $text-small;
  color: $main-color;
  text-decoration: none;
}
a:hover {
  color: $main-color;
  text-decoration: underline;
}
</style>
